export const calcKeep4 = str => {
  let string = String(str);
  let string2 = String(str);
  let index1 = string.lastIndexOf('.');
  let s = string2.substring(index1);
  s = s.length > 4 ? s.substring(0, 5) : s;
  return string.substring(0, index1) + s;
};
export const strKeep4 = str => {
  if (str.indexOf('.') === -1) str += '.0000';
  str = String(str).split('.');
  return `${str[0]}.${+str[1].length > 4 ? str[1].substring(0, 4) : str[1]}`;
};
export const strKeep2 = str => {
  if (str.indexOf('.') === -1) str += '.00';
  str = String(str).split('.');
  return `${str[0]}.${+str[1].length > 2 ? str[1].substring(0, 2) : str[1]}`;
};
