<template>
  <div class="vg_wrapper">
    <EditHeader
      :isShow="isShow"
      :btn="btn"
      :special="true"
      :strForm="{ status: formStatus }"
      @openEdit="openEdit"
      @closeEdit="closeEdit"
      @submit="submit"
      @refresh="initData"
    >
      <div slot="functionButton" class="vg_ml_8">
        <el-button :disabled="!isShow" v-if="formStatus === 2" class="vg_mb_8" type="success" size="small" @click="generateContract"> 生成托收合同 </el-button>
        <el-button :disabled="!isShow" class="vg_mb_8" type="success" size="small" @click="exportExcel"> 导出托收明细 </el-button>
        <el-button v-if="formStatus === 2" :disabled="!isShow" class="vg_mb_8" size="small" type="warning" @click="generateInvoicingNotification">
          生成开票通知单
        </el-button>
      </div>
      <div slot="otherButton">
        <div class="flexHV vg_mr_8">
          <span>托收单据号：</span>
          <el-input disabled size="small" :value="inform_no" style="width: 160px"></el-input>
        </div>
      </div>
      <div slot="specialStatus">
        <div class="flexHV">
          <span class="vg_tag_label">状态：</span>
          <el-tag :type="helper.getStatusName(formStatus).type">{{ helper.getStatusName(formStatus).name }}</el-tag>
        </div>
      </div>
    </EditHeader>
    <DynamicForm
      v-bind="$attrs"
      :total-width="mainForm.totalWidth"
      :split="mainForm.split"
      :form-list="mainForm.formProperties"
      ref="dynamicForm"
      class="dynamicForm"
      :is-show="isShow"
      :computedData="{
        contract_amount: contract_amount,
        total_weight: total_weight,
        remain_weight: remain_weight,
        // actual_amount: actual_amount,
        not_amount: not_amount
      }"
    />
    <div>
      <el-button :disabled="isShow" class="vg_mb_8" type="success" size="small" @click="split()">拆分</el-button>
      <el-button :disabled="isShow" class="vg_mb_8" type="info" size="small" @click="revoke()">撤销</el-button>
      <el-button :disabled="isShow" class="vg_mb_8" type="danger" size="small" @click="delRow()">删除</el-button>
      <span class="vd_tips vg_ml_8">提示：只可撤销上一步拆分的数据</span>
    </div>
    <DynamicUTable
      ref="dynamicUTable"
      :tableData="computedTableData"
      :columns="informSubTableProperties"
      :need-pagination="false"
      :is-show="isShow"
      :tableSelectionChange="val => (subSelections = val)"
      :computedData="{ collection_amount: collection_amount, swap_point: swap_point }"
    >
      <template v-slot:subItem-tran_no="scope">
        <el-link type="primary" class="vg_cursor" :disabled="isShow" @click="showTranDialog(scope.$index)">选择</el-link>
      </template>
    </DynamicUTable>
    <el-dialog title="拆分" :visible.sync="splitDialogVisible" width="35%">
      <el-form :inline="true" ref="splitForm" :model="splitForm" label-width="120px">
        <el-form-item label="托收重量:">
          <el-input-number v-model="splitForm.collection_weight" :max="splitForm.maxCWeight" :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="实际托收金额:">
          <el-input-number v-model="splitForm.actual_amount" :max="splitForm.maxCAmount" :min="0"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="splitConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="导入出运明细" :visible.sync="tranDialogVisible" width="50%" @opened="getTranDialogData">
      <search-table
        ref="tranDialog"
        :data="tranDialogTableData"
        :total-page="tranDialogTotal"
        :tableRowKey="'tran_prod_id'"
        :need-fixed-height="true"
        :columns="tranDialogTableProperties"
        @row-dblclick="importTranDataConfirm"
        @getTableData="getTranDialogData"
      />
    </el-dialog>
    <el-row class="vg_mt_16 vd_bortop">
      <el-col>
        <inputUser :isCookie="false" :stffForm="stffForm" ref="userData"></inputUser>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EditHeader from '@/views/component/editHeaderLine.vue';
import inputUser from '@/views/component/inputUser';
import DynamicForm from '@/components/DynamicFormV2.vue';
import { cloneDeep } from 'lodash';
import { informMainFormProperties, informSubTableProperties } from '@/views/CollectionManagement/InformManage/inform';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { objectArrayReduce, pushPagesSequentially } from '@assets/js/arrayUtils';
import { informAPI } from '@api/modules/inform';
import { changeStaffForm, downloadFile, getCptt, getSupp } from '@api/public';
import { BigNumber } from 'bignumber.js';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { getNoCatch } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import { strKeep4 } from '@assets/js/StringUtils';
import { requisitionAPI } from '@api/modules/requisition';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'informEditMain',
  components: {
    SearchTable,
    DynamicUTable,
    DynamicForm,
    EditHeader,
    inputUser
  },
  data() {
    return {
      isShow: true,
      btn: {},
      stffForm: {},
      splitForm: {},
      mainForm: {
        split: 8,
        totalWidth: 24,
        formProperties: cloneDeep(informMainFormProperties)
      },
      subTableData: [],
      subSelections: [],
      subTableMap: new Map(),
      informSubTableProperties: cloneDeep(informSubTableProperties),
      formStatus: 0,
      inform_no: '',
      inform_id: '',
      supp_aid: '',
      invoice_number: '',
      inform_remarks: '',
      backupData: [],
      splitDialogVisible: false,
      tranDialogVisible: false,
      tranDialogTableData: [],
      tranDialogTotal: 0,
      currentImportIndex: '',
      tranDialogTableProperties: [
        this.$store.state.index,
        { label: '出运发票号', prop: 'tran_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '报关金额($)', prop: 'prod_subtotal', itemType: 'input', input: false, sortable: false, widthAuto: true }
      ]
    };
  },
  computed: {
    computedTableData() {
      return this.subTableData.filter(x => x.destroy_flag !== 1);
    },
    // 合同托收金额 = 子表托收金额之和
    contract_amount() {
      let index = this.mainForm.formProperties.findIndex(({ prop }) => prop === 'contract_amount');
      let calcNum = objectArrayReduce(this.computedTableData, 'collection_amount');
      this.mainForm.formProperties[index].model = calcNum;
      return calcNum;
    },

    // 总托收重量 = 合同托收金额 / 100 ; 四舍五入
    total_weight() {
      let index = this.mainForm.formProperties.findIndex(({ prop }) => prop === 'total_weight');
      let temp = new BigNumber(this.contract_amount).div(100).toFixed(2);
      this.mainForm.formProperties[index].model = temp;
      return temp;
    },

    // 剩余托收重量 = 实际托收重量 - 子表托收重量之和
    remain_weight() {
      let index = this.mainForm.formProperties.findIndex(({ prop }) => prop === 'remain_weight');
      let aIndex = this.mainForm.formProperties.findIndex(({ prop }) => prop === 'actual_weight');
      let calcNum = objectArrayReduce(this.computedTableData, 'collection_weight');
      let temp = new BigNumber(this.mainForm.formProperties[aIndex].model).minus(calcNum);
      if (!temp.isFinite()) {
        this.mainForm.formProperties[index].model = '';
        return '自动计算';
      }
      this.mainForm.formProperties[index].model = temp.toFixed(4);
      return temp.toFixed(4);
    },

    // 实际托收金额 = 子表实际托收金额之和
    /* actual_amount() {
      let index = this.mainForm.formProperties.findIndex(({ prop }) => prop === 'actual_amount');
      let calcNum = objectArrayReduce(this.computedTableData, 'actual_amount');
      let temp = new BigNumber(calcNum);
      if (!temp.isFinite()) {
        this.mainForm.formProperties[index].model = '';
        return '自动计算';
      }
      this.mainForm.formProperties[index].model = temp.toFixed(4);
      return temp.toFixed(4);
    },*/

    // 未托收金额 = 合同托收金额 - 实际托收金额 (此公式弃用)
    // 未托收金额 = 实际托收金额 - 子表托收金额之和 (V2.0)
    not_amount() {
      let index = this.mainForm.formProperties.findIndex(({ prop }) => prop === 'not_amount');
      let actual_amount = this.mainForm.formProperties.find(({ prop }) => prop === 'actual_amount').model;
      let calcNum = objectArrayReduce(this.computedTableData, 'actual_amount');
      let temp = new BigNumber(actual_amount).minus(calcNum);
      if (!temp.isFinite()) {
        this.mainForm.formProperties[index].model = '';
        return '自动计算';
      }
      this.mainForm.formProperties[index].model = temp.toFixed(4);
      return temp.toFixed(4);
    },

    inform_rate() {
      // 费率下标
      let rateIndex = this.mainForm.formProperties.findIndex(({ prop }) => prop === 'inform_rate');
      return this.mainForm.formProperties[rateIndex].model;
    },

    // 子表托收金额 = 合同总额 * (1 + 费率)
    collection_amount() {
      let rate = this.inform_rate;
      return index => {
        if (!this.computedTableData[index].collection_amount) {
          return 0;
        }
        // 合同总额
        let temp = new BigNumber(this.computedTableData[index].modr_total).times(1 + Number(rate));
        if (!temp.isFinite()) {
          this.computedTableData[index].collection_amount = '';
          return '自动计算';
        }
        this.computedTableData[index].collection_amount = temp.toFixed(4);
        return temp.toFixed(4);
      };
    },

    // 子表换汇比例 = (子表托收金额 + 采购总额) / 原报关总额(来源为出运发票号选择)
    swap_point() {
      return index => {
        let { collection_amount, price_sum, customs_total } = this.computedTableData[index];
        let temp = new BigNumber(collection_amount).plus(price_sum).div(customs_total);
        if (!temp.isFinite()) {
          this.computedTableData[index].swap_point = '';
          return '自动计算';
        }
        this.computedTableData[index].swap_point = temp.toFixed(4);
        return temp.toFixed(4);
      };
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      this.getInformData();
      await this.changeMainFormProperties();
    },
    async changeMainFormProperties() {
      let index = this.mainForm.formProperties.findIndex(({ prop }) => prop === 'supp_bid');
      this.mainForm.formProperties[index].options = await getSupp('default', [], 'supp_abbr', { supp_factory: 1 });

      this.mainForm.formProperties.find(({ prop }) => prop === 'cptt_id').options = await getCptt();

      let rateIndex = this.mainForm.formProperties.findIndex(({ prop }) => prop === 'inform_rate');
      this.mainForm.formProperties[rateIndex].change = val => this.rateChange(val);

      let prodHsnameIndex = this.informSubTableProperties.findIndex(({ prop }) => prop === 'prod_hsname');
      getNoCatch(optnAPI.getOptnNameByPermId, { perm_id: 10019 }).then(
        ({ data }) => (this.informSubTableProperties[prodHsnameIndex].subItem.options = Array.from(data.form, item => ({ value: item, label: item })))
      );
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    getInformData() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      informAPI.getInformById({ inform_id: props.form_id }).then(({ data }) => {
        this.btn = data.btn;
        this.inform_no = data.form.inform_no;
        this.inform_id = data.form.inform_id;
        this.supp_aid = data.form.supp_aid;
        this.formStatus = data.form.status;
        this.fective_time = data.form.fective_time;
        this.invoice_number = data.form.invoice_number;
        this.inform_remarks = data.form.inform_remarks;
        this.mainForm.formProperties.forEach(item => (item.model = data.form[item.prop]));
        this.subTableData = data.form.inFromModrList;
        changeStaffForm(this.stffForm, data.form);
      });
    },
    rateChange(val) {
      this.computedTableData.forEach((item, index) => {
        this.collection_amount(index);
      });
    },
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let dynamicUTable = await this.$refs.dynamicUTable.fullValidateMsg();
          this.$refs.dynamicForm.$refs.form.validate((valid, object) => {
            if (valid && !dynamicUTable) {
              this.saveInfo();
            } else {
              let tempStr = '';
              if (dynamicUTable) tempStr += `子表-${dynamicUTable}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch(err => console.error(err));
    },
    saveInfo() {
      informAPI.editInform(this.getSaveData()).then(({ data }) => {
        this.$message.success('保存成功!');
        this.initData();
        this.isShow = true;
        this.$emit('isShow', this.isShow);
      });
    },
    getSaveData() {
      let formModel = this.$refs.dynamicForm.getFormModel();
      formModel.inform_id = this.inform_id;
      formModel.supp_aid = this.supp_aid;
      formModel.inform_no = this.inform_no;
      formModel.fective_time = this.fective_time;
      formModel.inFromModrList = this.subTableData.filter(x => (x.inform_modr_id ? true : !x.inform_modr_id && !x.inform_modr_id));
      return formModel;
    },
    delRow() {
      if (this.subSelections.length === 0) return this.$message.warning('请选择数据!');
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.subSelections.forEach(item => (item.destroy_flag = 1));
        })
        .catch(err => console.error(err));
    },
    revoke() {
      if (this.backupData.length === 0) return;
      this.subTableData[this.backupData.index + 1] = this.backupData;
      this.subTableData = this.subTableData.filter((item, index) => index !== 0);
      this.backupData = [];
    },
    split() {
      let selection = cloneDeep(this.subSelections[0]);
      if (!selection) return this.$message.warning('请选择一条数据!');
      this.splitDialogVisible = true;
      this.splitForm.maxCWeight = selection.collection_weight - 1;
      this.splitForm.maxCAmount = selection.actual_amount - 1;
    },
    splitConfirm() {
      if (!this.splitForm.collection_weight || !this.splitForm.actual_amount) return this.$message.warning('请输入重量或金额!');
      this.backupData = cloneDeep(this.subSelections[0]);
      const { collection_weight, actual_amount } = this.subSelections[0];
      const splitObj = cloneDeep(this.subSelections[0]);
      splitObj.collection_weight = this.splitForm.collection_weight;
      splitObj.actual_amount = this.splitForm.actual_amount;
      splitObj.collection_amount = 0;
      splitObj.inform_modr_id = 0;

      const findIndex = this.subTableData.findIndex(x => x.index === splitObj.index);
      const updateValue = (original, split) => strKeep4(new BigNumber(Number(original)).minus(split).toString());

      // 不四舍五入
      this.subTableData[findIndex].collection_weight = updateValue(collection_weight, this.splitForm.collection_weight);
      this.subTableData[findIndex].actual_amount = updateValue(actual_amount, this.splitForm.actual_amount);

      // 添加至列表顶部
      this.subTableData.unshift(splitObj);

      this.$refs.dynamicUTable.clearSelection();
      this.splitForm = {};
      this.splitDialogVisible = false;
    },

    getTranDialogData() {
      informAPI.getInformTran(this.$refs.tranDialog.searchForm).then(({ data }) => {
        this.tranDialogTableData = data.list;
        this.tranDialogTotal = data.total;
      });
    },
    importTranDataConfirm(row) {
      this.subTableData[this.currentImportIndex].tran_id = row.tran_id;
      this.subTableData[this.currentImportIndex].tran_no = row.tran_no;
      this.subTableData[this.currentImportIndex].customs_total = row.prod_subtotal;
      // 采购总价
      this.subTableData[this.currentImportIndex].price_sum = row.price_sum;
      this.tranDialogVisible = false;
    },
    showTranDialog(index) {
      this.currentImportIndex = index;
      this.tranDialogVisible = true;
    },
    generateContract() {
      informAPI.addContracts({ inform_id: this.inform_id }).then(({ data }) => {
        this.jump('/contract_edit', {
          key: UrlEncode.encode(
            JSON.stringify({
              perm_id: 177,
              form_id: data.form_id
            })
          )
        });
      });
    },
    exportExcel() {
      let requestBody = { type: 1, inform_id: this.inform_id };
      informAPI.exportInformById(requestBody).then(({ data }) => {
        downloadFile({ fileUrl: data.url, fileName: data.pdf_name, type: '.xlsx' });
      });
    },
    generateInvoicingNotification() {
      if (this.subTableData.some(item => !item.tran_no)) return this.$message.warning('有未选择的托收出运发票号!');
      requisitionAPI.addRequisitionById(this.getSaveData()).then(({ data }) => {
        pushPagesSequentially(data.form_id, 'requisitionEdit', 179);
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
